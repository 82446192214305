/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
            

        


        var $grid = $('.masonry').imagesLoaded( function() {
          $grid.isotope({
            itemSelector: '.item',
            percentPosition: true,
            layoutMode: 'masonry'
          });
        });

            $('.galerij').each( function() {
              var $pic     = $(this),
                  getItems = function() {
                      var items = [];
                      $pic.find('figure a').each(function() {
                          var $href   = $(this).attr('href'),
                              $size   = $(this).data('size').split('x'),
                              $width  = $size[0],
                              $height = $size[1];

                          var item = {
                              src : $href,
                              w   : $width,
                              h   : $height
                          }

                          items.push(item);
                      });
                      return items;
                  }

              var items = getItems();

             // console.log(items);


              var $pswp = $('.pswp')[0];


              $pic.on('click', 'figure', function(event) {
                  event.preventDefault();

                  var $index = $(this).index();
                  var options = {
                      index: $index,
                      bgOpacity: 0.7,
                      showHideOpacity: true
                  }

                  // Initialize PhotoSwipe
                  var lightBox = new PhotoSwipe($pswp, PhotoSwipeUI_Default, items, options);
                  lightBox.init();
              });


          });


          $('.tiled-gallery').each( function() {
            var $pic     = $(this),
                getItems = function() {
                    var items = [];
                    $pic.find('.tiled-gallery-item a img').each(function() {
                        var $href   = $(this).data('orig-file'),
                            $size   = $(this).data('orig-size').split(','),
                            $width  = $size[0],
                            $height = $size[1];

                        var item = {
                            src : $href,
                            w   : $width,
                            h   : $height
                        }

                        items.push(item);
                    });
                    return items;
                }

            var items = getItems();

           // console.log(items);


            var $pswp = $('.pswp')[0];


            $pic.on('click', 'a img', function(event) {
                event.preventDefault();

                var $index = $(this).index();
                var options = {
                    index: $index,
                    bgOpacity: 0.7,
                    showHideOpacity: true
                }

                // Initialize PhotoSwipe
                var lightBox = new PhotoSwipe($pswp, PhotoSwipeUI_Default, items, options);
                lightBox.init();
            });


        });
           
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $('#my-slider').sliderPro({
            width: '100%',
            autoplay: true,
            arrows: true,
            fadeArrows: false,
            autoHeight: true
        });
        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'diensten': {
      init: function() {
        // JavaScript to be fired on the about us page
        $( '#my-slider' ).sliderPro({
            width: '100%',
            autoplay: false,
            arrows: true,
            buttons: true,
            autoHeight: true
        }); 
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
